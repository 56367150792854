 <template>
	<el-form class="add_form" status-icon label-position="left" label-width="100px">
		<div class="item">
			<div class="title">基本信息</div>
			<div class="inner">
				<el-table :data="normal_data" :border="true" :stripe="true" size="small">
					<el-table-column label="车型名称">
						<template slot-scope="scope">
							<el-input class="el_input" v-model="scope.row.name"></el-input>
						</template>
					</el-table-column>
					<el-table-column label="车长(米)">
						<template slot-scope="scope">
							<el-input class="el_input" v-model="scope.row.length" type="number"></el-input>
						</template>
					</el-table-column>
					<el-table-column label="车宽(米)">
						<template slot-scope="scope">
							<el-input class="el_input" v-model="scope.row.width" type="number"></el-input>
						</template>
					</el-table-column>
					<el-table-column label="车高(米)">
						<template slot-scope="scope">
							<el-input class="el_input" v-model="scope.row.height" type="number"></el-input>
						</template>
					</el-table-column>
					<el-table-column label="额定载重(吨)">
						<template slot-scope="scope">
							<el-input class="el_input" v-model="scope.row.weight" type="number"></el-input>
						</template>
					</el-table-column>
					<el-table-column label="额载体积(方)">
						<template slot-scope="scope">
							<el-input class="el_input" v-model="scope.row.volume" type="number"></el-input>
						</template>
					</el-table-column>
					<el-table-column label="起步价(元)">
						<template slot-scope="scope">
							<el-input class="el_input" v-model="scope.row.starting_price" type="number"></el-input>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<div class="item">
			<div class="title">细分车型 / 主车型后续里程报价</div>
			<div class="inner" style="justify-content: space-between;">
				<div class="meal">
					<el-table :data="meal" :border="true" :stripe="true" size="small">
						<el-table-column label="细分车型名称">
							<template slot-scope="scope">
								<el-input class="el_input" v-model="scope.row.meal_name"></el-input>
							</template>
						</el-table-column>
						<el-table-column label="上报监管平台车型名称">
							<template slot-scope="scope">
								<el-cascader class="el_input" v-model="scope.row.logink_truck_type" placeholder="非必填" :options="logink_truck_type" filterable></el-cascader>
							</template>
						</el-table-column>
						<el-table-column label="起步价(元/公里)">
							<template slot-scope="scope">
								<el-input class="el_input" v-model="scope.row.meal_price" type="number"></el-input>
							</template>
						</el-table-column>
						
						<!-- 操作行 -->
						<el-table-column fixed="right" label="操作" width="100">
							<template slot-scope="scope">
								<el-button size="mini" type="text" @click="meal_add">添加</el-button>
								<el-button size="mini" type="text" v-if="scope.$index!=0" @click="meal_del(scope.$index)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="other_price">
					<el-table :data="other_price" :border="true" :stripe="true" size="small">
						<el-table-column label="起始里程(公里)">
							<template slot-scope="scope">
								<el-input class="el_input" v-model="scope.row.start_mileage"></el-input>
							</template>
						</el-table-column>
						<el-table-column label="终止里程(公里)">
							<template slot-scope="scope">
								<el-input class="el_input" v-model="scope.row.end_mileage"></el-input>
							</template>
						</el-table-column>
						<el-table-column label="里程内单价(元/公里)">
							<template slot-scope="scope">
								<el-input class="el_input" v-model="scope.row.mileage_price"></el-input>
							</template>
						</el-table-column>
						
						<!-- 操作行 -->
						<el-table-column fixed="right" label="操作" width="100">
							<template slot-scope="scope">
								<el-button size="mini" type="text" @click="other_price_add">添加</el-button>
								<el-button size="mini" type="text" v-if="scope.$index!=0" @click="other_price_del(scope.$index)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<div class="bottom_btns">
			<el-button type="primary" @click="add_view_sub">提交</el-button>
		</div>
	</el-form>
</template>

<script>
	export default {
		props:{
			truck_type:Object,
			logink_truck_type:Array
		},
		data() {
			return {

				//id
				id:0,

				//基本数据
				normal_data:[{
					
					//车型名称
					name:'',

					//长宽高
					length:'',//长
					width:'',//宽
					height:'',//高

					//载重信息
					weight:'',//额定载重
					volume:'',//额载体积

					//起步价
					starting_price:'',
				}],
				
				//后续历程报价
				other_price:[{
						start_mileage:'',//起始里程
						end_mileage:'',//终止里程
						mileage_price:'',//价格
					}
				],

				//细分车型
				meal:[{
						meal_name:'',//细分车型名称
						logink_truck_type:null,//上报监管平台车型名称
						meal_price:'',//细分车型起步价
					}
				]
			}
		},
		watch:{
			truck_type(new_data){

				//初始化
				this.init(new_data);
			},
		},
		created(){

			//初始化
			this.init(this.truck_type);
		},
		methods:{

			//提交
			add_view_sub(){

				if(!this.normal_data[0].name.trim()){
					this.$my.other.msg({
						type:'warning',
						str:'缺少车型名称'
					});
					return;
				}else this.normal_data[0].name=this.normal_data[0].name.trim();

				if(!this.$my.check.is_num(this.normal_data[0].length)){
					this.$my.other.msg({
						type:'warning',
						str:'缺少车长'
					});
					return;
				}

				if(!this.$my.check.is_num(this.normal_data[0].width)){
					this.$my.other.msg({
						type:'warning',
						str:'缺少车宽'
					});
					return;
				}

				if(!this.$my.check.is_num(this.normal_data[0].height)){
					this.$my.other.msg({
						type:'warning',
						str:'缺少车高'
					});
					return;
				}

				if(!this.$my.check.is_num(this.normal_data[0].weight)){
					this.$my.other.msg({
						type:'warning',
						str:'缺少载重'
					});
					return;
				}

				if(!this.$my.check.is_num(this.normal_data[0].volume)){
					this.$my.other.msg({
						type:'warning',
						str:'缺少额载体积'
					});
					return;
				}

				if(!this.$my.check.is_num(this.normal_data[0].starting_price)){
					this.$my.other.msg({
						type:'warning',
						str:'缺少起步价'
					});
					return;
				}

				let post_data={
					mod:'truck',
					ctr:'truck_type_edit_admin',
					truck_type_id:this.id,
					name:this.normal_data[0].name,
					length:this.normal_data[0].length,
					width:this.normal_data[0].width,
					height:this.normal_data[0].height,
					weight:this.normal_data[0].weight,
					volume:this.normal_data[0].volume,
					starting_price:this.normal_data[0].starting_price,
					meal:[],
					other_price:[],
				}

				//车型可选项(json)(类似于商品套餐)
				for(var item of this.meal){
					item.meal_name=item.meal_name.trim()
					if(!item.meal_name){
						break;
					}
					if(!this.$my.check.is_num(item.meal_price)){
						break;
					}
					if(item.meal_price<=0){
						break;
					}
					post_data.meal.push({...item})
				}

				//后续里程价格(json)
				for(var item of this.other_price){
					
					if(!this.$my.check.is_num(item.start_mileage)){
						break;
					}
					if(item.start_mileage<=0){
						break;
					}

					if(!this.$my.check.is_num(item.end_mileage)){
						break;
					}
					if(item.end_mileage<=0){
						break;
					}

					if(!this.$my.check.is_num(item.mileage_price)){
						break;
					}
					if(item.mileage_price<=0){
						break;
					}

					post_data.other_price.push({...item})
				}

				if(post_data.meal.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'缺少细分车型'
					});
					return;
				}

				if(post_data.other_price.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'缺少后续里程报价'
					});
					return;
				}

				//提交
				this.$my.net.req({
					data:post_data,
					callback:(data)=>{
						this.$emit('end_of_sub',{})
					}
				});
			},

			//删除细分车型
			meal_del(index){
				this.meal.splice(index,1);
			},

			//添加细分车型
			meal_add(){
				this.meal.push({
					meal_name:'',//细分车型名称
					logink_truck_type:null,//上报监管平台车型名称
					meal_price:'',//细分车型起步价
				});
			},

			//删除后续历程报价
			other_price_del(index){
				this.other_price.splice(index,1);
			},

			//添加后续里程报价
			other_price_add(){
				this.other_price.push({
					start_mileage:'',//起始里程
					end_mileage:'',//终止里程
					mileage_price:'',//价格
				});
			},

			//初始化
			init(truck_type){

				//置入基本数据
				this.id=truck_type.id
				this.normal_data[0].name=truck_type.name
				this.normal_data[0].length=truck_type.length
				this.normal_data[0].width=truck_type.width
				this.normal_data[0].height=truck_type.height
				this.normal_data[0].weight=truck_type.weight
				this.normal_data[0].volume=truck_type.volume
				this.normal_data[0].starting_price=truck_type.starting_price
				
				//置入后续历程报价
				this.other_price=[];
				for(var item of truck_type.other_price){
					this.other_price.push({
						start_mileage:item.start_mileage,
						end_mileage:item.end_mileage,
						mileage_price:item.mileage_price,
					});
				}

				//置入细分车型
				this.meal=[];
				for(var item of truck_type.meal){
					this.meal.push({
						meal_name:item.meal_name,
						logink_truck_type:item.logink_truck_type,
						meal_price:item.meal_price,
					});
				}
			}
		}
	}
</script>

<style lang="scss" scoped>

	//底部提交按钮
	.bottom_btns{
		width: 100%;
		text-align: center;
		position: absolute;
		bottom: 0px;
		left: 0;
		padding: 20px 0;
		border-top: 1px solid #ddd;
	}

	.meal{
		width: 48%;
		height: 380px;
		overflow: auto;
	}
	.other_price{
		width: 48%;
		height: 380px;
		overflow: auto;
	}

	//表单
	.add_form{
		height: calc(100% - 70px);
		.item{

			.title{
				background-color: #eee;
				font-size: 16px;
				font-weight: 600;
				padding: 14px 20px;
			}
			.inner{
				display: flex;
				flex-wrap: wrap;
				padding: 20px 20px;
			}

			.el_input{
				width: 90%;
			}

			.upl_ctr{
				width: 200px;
				height: 120px;
				border: 1px solid #DCDFE6;
				.preview{
					width: 100%;
					height: 100%;
				}
				.upl_btn{
					height: 120px;
					line-height: 120px;
				}
			}

		}
	}
</style>