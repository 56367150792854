 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="90px">
			<el-form-item class="el_form_item" label="车型名称">
				<el-input class="el_input" v-model="form.name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="是否显示">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="是" value="1"></el-option>
					<el-option label="否" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="ser">查询</el-button>
				<el-button type="success" @click="open_add_view">添加</el-button>
			</el-form-item>
		</el-form>


		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small"  v-loading="list.loading">
				<el-table-column label="车型名称" prop="name"></el-table-column>
				<el-table-column label="尺寸(长宽高:米)">
					<template slot-scope="scope">
						<div>{{scope.row.length}} / {{scope.row.width}} / {{scope.row.height}}</div>
					</template>
				</el-table-column>
				<el-table-column label="载重/体积">
					<template slot-scope="scope">
						<div>{{scope.row.weight}}吨 / {{scope.row.volume}}方</div>
					</template>
				</el-table-column>
				<el-table-column label="起步价(元)" prop="starting_price"></el-table-column>
				
				<!-- 排序 -->
				<el-table-column fixed="right" label="排序" width="130">
					<template slot-scope="scope">
						<el-button size="mini" @click="move_up(scope.$index)" icon="el-icon-top"></el-button>
						<el-button size="mini" @click="move_down(scope.$index)" icon="el-icon-bottom"></el-button>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column fixed="right" label="操作" width="190">
					<template slot-scope="scope">
						<el-button @click="open_edit_view(scope.row)" size="mini" type="primary">修改</el-button>
						<el-button @click="del(scope.row)" size="mini" type="danger">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 添加 -->
		<el-dialog top="1vh" title="添加车型" width="90%" :visible.sync="add_form_is_show">
			<div style="height:700px;overflow:auto">
				<truckTypeAdd :logink_truck_type="logink_truck_type" @end_of_sub="close_add_view"></truckTypeAdd>
			</div>
		</el-dialog>

		<!-- 编辑 -->
		<el-dialog top="1vh" title="修改车型" width="90%" :visible.sync="edit_form_is_show">
			<div style="height:700px;overflow:auto">
				<truckTypeEdit :truck_type="edit_data" :logink_truck_type="logink_truck_type" @end_of_sub="close_edit_view"></truckTypeEdit>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import truckTypeAdd from './truck_type_add.vue'
	import truckTypeEdit from './truck_type_edit.vue'
	export default {
		components:{
			truckTypeAdd,
			truckTypeEdit,
		},
		data() {
			return {

				//搜索条件
				form: {
					name:'',//车型名称
					status:'',//显示状态
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//上报用的货车类型
				logink_truck_type:[],

				//添加弹出层
				add_form_is_show:false,

				//编辑弹出层
				edit_form_is_show:false,
				edit_data:{},
			}
		},
		created() {

			//获取上报用的货车类型
			this.get_logink_truck_type();
			
			//获取页面数据
			this.get_page_data()
		},
		methods: {

			//下移
			move_down(index){

				//如果是最上边一个
				if(index==this.list.data.length-1){
					return;
				}

				//取出当前行数据
				let this_item=this.list.data[index]

				//取出下一行数据
				let next_item=this.list.data[index+1];

				//调用接口
				this.$my.net.req({
					data:{
						mod:'truck',
						ctr:'truck_types_edit_admin',
						need_edit:[{
								truck_type_id:this_item.id,
								sort:next_item.sort,
							},{
								truck_type_id:next_item.id,
								sort:this_item.sort,
							}
						]
					},
					callback:(data)=>{

						//报告结果
						this.$my.other.msg({
							type:'success',
							str:'操作成功'
						});

						//刷新数据
						this.get_page_data();
					}
				});
			},

			//上移
			move_up(index){

				//如果是最上边一个
				if(index==0){
					return;
				}

				//取出当前行数据
				let this_item=this.list.data[index]

				//取出上一行数据
				let last_item=this.list.data[index-1];

				//调用接口
				this.$my.net.req({
					data:{
						mod:'truck',
						ctr:'truck_types_edit_admin',
						need_edit:[{
								truck_type_id:this_item.id,
								sort:last_item.sort,
							},{
								truck_type_id:last_item.id,
								sort:this_item.sort,
							}
						]
					},
					callback:(data)=>{

						//报告结果
						this.$my.other.msg({
							type:'success',
							str:'操作成功'
						});

						//刷新数据
						this.get_page_data();
					}
				});
			},

			//关闭添加页面
			close_edit_view(){

				//关闭弹出层
				this.edit_form_is_show=false;

				//刷新数据
				this.get_page_data();
			},

			//打开修改页面
			open_edit_view(item){

				//打开弹出层
				this.edit_form_is_show=true;

				//置入数据
				this.edit_data={...item}
			},

			//删除操作
			del(item){

				//询问
				this.$my.other.confirm({
					content:"确认删除'"+item.name+"'",
					confirm:()=>{

						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck',
								ctr:'truck_type_del_admin',
								truck_type_id:item.id
							},
							callback:(data)=>{

								//刷新
								this.get_page_data();
							}
						});
					}
				});
			},

			//关闭添加页面
			close_add_view(){

				//关闭弹出层
				this.add_form_is_show=false;

				//刷新数据
				this.get_page_data();
			},

			//打开添加页面
			open_add_view(){
				
				//打开弹出层
				this.add_form_is_show=true;
			},

			//搜索
			ser(){
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取页面数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck',
						ctr:'truck_type_list',
						...this.form,
						...this.page
					},
					callback:(data)=>{
						
						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//细分车型
							item.meal=JSON.parse(item.meal);

							//额外价格
							item.other_price=JSON.parse(item.other_price);
						}

						//渲染
						this.list.data=data.list
					}
				})
			},

			//获取上报用的货车类型
			get_logink_truck_type(){
				
				//尝试从缓存中取出
				let logink_truck_type=this.$my.cache.get('logink_truck_type');

				//如果取出成功
				if(logink_truck_type){
					this.logink_truck_type=logink_truck_type
					return
				}

				//如果取出失败,去服务器读取
				this.$my.net.req({
					data:{
						mod:'truck',
						ctr:'get_logink_truck_type'
					},
					callback:(data)=>{

						//置入
						this.logink_truck_type=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//用户修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 80px);
	}
	.el_form_item{
		width: 275px;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>